<template>
  <div class="vendor-resources-group">
    <router-view
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
